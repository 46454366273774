import React,{useContext} from 'react';
import { OmModal } from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import { EClaimContext } from '../../context/EClaimContext';

const Feedback = ({open, onCloseHandler}) => {

    const { eClaimData, } = useContext(EClaimContext);

    const dowloadFile = () => window.open(eClaimData?.schemeDetails?.pdf_file, '_blank');

    return (
        <OmModal
            open={open}
            isCentered="true"
            overlayName="cancel-eclaim-journey">
            <div slot="modal-content" className="cancel-modal">
                <p className="cancel-modal-title">Kindly click on the link below to rate your experience on our Instant Claims Platform</p>
        
                <a target="_blank" rel="noopener noreferrer"
                    style={{color: '#50b848', marginBottom: '7px'}}
                    href="https://forms.office.com/r/izQGLRBhsD">Rate our service</a>

                <div className="cancel-modal-buttons">
                    <OmButton
                        type="primary"
                        text='Download Receipt'
                        size="small"
                        onClick={dowloadFile}
                    />

                    <OmButton
                        type="primary"
                        text='Close'
                        size="small"
                        onClick={onCloseHandler}
                    />
                </div>
            </div>
        </OmModal>
    );
};

export default Feedback;
