import React, { useContext } from 'react';
import './Index.scss';
import { PAGE_INDICES } from '../constants/routing';
import { EClaimContext } from '../context/EClaimContext';
import SuccessPage from './successPage/successPage';
import ValidatePolicyPage from './policy/validatePolicyPage';
import DetailSummaryPage from './detailSummaryPage/DetailSummary';
import ClaimPage from './claimPage/claimFormPage';
import GhanaCardPage from './ghanaCard/ghanaCardPage';

const renderComponent = (action) => {
    switch (action) {
    case PAGE_INDICES.VALIDATE_GHANA_CARD:
        return <GhanaCardPage />;
    case PAGE_INDICES.VALIDATE_POLICY:
        return <ValidatePolicyPage />;
    case PAGE_INDICES.SUMMARY_DETAILS:
        return <DetailSummaryPage />;
    case PAGE_INDICES.CLAIM_FORM:
        return <ClaimPage />;
    case PAGE_INDICES.SUCCESS:
        return <SuccessPage />;
    default:
        return null;
    }
};

const Outlet = () => {
    const { claimActivePageIndex } = useContext(EClaimContext);
    return <>{renderComponent(claimActivePageIndex)}</>;
};

export default Outlet;
