import React, { useContext, useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../configs/eclaim-configs/config';
import { OmModal } from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import { EClaimContext } from '../../../eclaim/context/EClaimContext';
import {OmFormInputFieldWrapper, OmModalLoader} from '@om/component-library-react';
import { isInputValid } from '../../../../utils/eclaim-utils/util';
import { validatePolicyOTPFromSlams } from '../../server/api';

const ValidateOTP = ({open, onCloseHandler, mobile, token}) => {
    // const { idToRemove, deleteBeneficiary } = useContext(DigitalFuneralContext);
    const { eClaimData, updateEClaimData, navigateContinue } = useContext(EClaimContext);
    const [otp, setOTP] = useState(undefined)

    const submitOTP = async () => {
        if(!otp){
            alert(`Enter your OTP received on ${mobile} to verify!`)
            return;
        }
        toggleLoading(true)
        const result = await validatePolicyOTPFromSlams(token, eClaimData?.schemeDetails?.policy_number, otp)
        if(result){
            updateEClaimData({ validatedOTP: true }, 'schemeDetails');
            toggleLoading(false)
            onCloseHandler()
            navigateContinue()
        }else{
            toggleLoading(false)
            alert('Sorry we could not verify the OTP!')
        }
    }

    const toggleLoading = (val) => {
        updateEClaimData({ finalSubmit: val }, 'schemeDetails')
        sessionStorage.setItem('toggleProcess', val)
    }

    return (

        <OmModal
            open={open}
            isCentered="true"
            overlayName="cancel-eclaim-journey">
            <div slot="modal-content" className="cancel-modal">
                <p className="cancel-modal-title">Enter your security code sent to phone number ({mobile})</p>
                <OmFormInputFieldWrapper
                    label="OTP"
                    id="otp"
                    name="otp"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="schemeDetailsForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="e.g. 1234"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={otp}
                    state={isInputValid(otp)}
                    onPersist={(e) => setOTP(e.detail)}
                />
                <div className="cancel-modal-buttons">
                    {/* <OmButton
                        type="secondary"
                        text="cancel"
                        size="small"
                        onClick={() => onCloseHandler()}
                    /> */}
                    <OmButton
                        type="primary"
                        text={eClaimData?.schemeDetails?.finalSubmit ? 'Verifying...' : 'Verify OTP'}
                        size="small"
                        onClick={submitOTP}
                        disabled={eClaimData?.schemeDetails?.finalSubmit}
                    />
                </div>
            </div>
            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait..."
                verticalAlignMiddle={true}
                open={eClaimData?.schemeDetails?.finalSubmit}
            />
        </OmModal>
    );
};

export default ValidateOTP;
