import React, { useContext, useState, useRef, useEffect } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/eclaim-configs/config';
import {OmFormInputFieldWrapper, OmFormDropdownFieldWrapper, OmFormError, OmIcon, OmModalLoader, OmApplicationReviewSection,OmLabeledValueSummary} from '@om/component-library-react';
import { EClaimContext } from '../../context/EClaimContext';
import {isInputValid, formatFileSize, isValidGhanaCardNumber, getTelcoReference } from '../../../../../ghana/utils/eclaim-utils/util';
import { OmdsFileUploadFile, OmdsIcon, OmdsCell,OmdsGrid } from '@mom-omds/react-design-system-components/dist/generated/components';
import file_icon_jpeg from '../../assets/icons/File_Icon_JPEG.svg'
import file_icon_jpg from '../../assets/icons/File_Icon_JPG.svg'
import file_icon_pdf from '../../assets/icons/File_Icon_PDF.svg'
import file_icon_png from '../../assets/icons/File_Icon_PNG.svg'
import file_icon_tif from '../../assets/icons/File_Icon_TIF.svg'
import file_icon_tiff from '../../assets/icons/File_Icon_TIFF.svg'
import { generateToken, getCashValueFromSlams, getPurposesFromSlams, getTelcosFromSlams, verifyMoMoWllet } from '../../server/api';
import Disclaimer from './Disclaimer';


const ClaimPage = () => {

    const { eClaimData, updateEClaimData, memberIdCardFilename, memberIdCard, handleFileInput, removeFile, memberSignature, memberSignatureFilename } = useContext(EClaimContext);

    const [telcos, setTelcos] = useState([])
    const [purposes, setPurposes] = useState([])
    const [loading, setLoading] = useState(false)
    const [walletName, setWalletName] = useState(null)
    const [disclaimer, setDisclaimer] = useState(false)

    const memberIdCardInputFile = useRef(null)
    const memberSignatureInputFile = useRef(null)

    useEffect( () => {
        initDataLoad()
    },[])

    const toggleLoading = () => {setLoading(!loading)}

    const initDataLoad = async () => {
        
        toggleLoading()
        if(localStorage.getItem('telcos') == null){
            const _token = await generateToken();

            const telcos = await getTelcosFromSlams(_token)
            setTelcos(telcos)
            localStorage.setItem('telcos', JSON.stringify(telcos))

            const purposes = await getPurposesFromSlams(_token)
            setPurposes(purposes)
            localStorage.setItem('purposes', JSON.stringify(purposes))

            // const cashValue = await getCashValueFromSlams(_token, eClaimData?.schemeDetails?.policy_number)
            // updateEClaimData({ TotalCashValue: cashValue }, 'schemeDetails');
            // updateEClaimData({ CurrentCashValue: cashValue }, 'schemeDetails');
            // localStorage.setItem('CASH_VALUE', cashValue)

            setLoading(false)
        }else{
            setTelcos(JSON.parse(localStorage.getItem('telcos')))
            setPurposes(JSON.parse(localStorage.getItem('purposes')))
            // updateEClaimData({ TotalCashValue: eClaimData?.schemeDetails?.TotalCashValue }, 'schemeDetails');
            // updateEClaimData({ CurrentCashValue: eClaimData?.schemeDetails?.CurrentCashValue }, 'schemeDetails');
            setLoading(false)
        }
    }

    const handleChange = async (e) => {
        const value = e.detail;
        const input = e.target.name;

        if(input === 'AmountAppliedFor'){
            if(parseFloat(eClaimData?.schemeDetails?.AmountAppliedFor) > 1000){
                updateEClaimData({ [input]: null }, 'schemeDetails');
                return;
            }
        }else if(input === 'ClaimDefaultMobileWallet'){
            if(value.length == '10' && eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany){
                const resp = await verifyMoMoWllet(getTelcoReference(eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany), value)
                if(resp.success == 'false'){
                    alert('Error: '+resp.message)
                    return;
                }else{
                    setWalletName(resp?.MOMOWalletName)
                    setDisclaimer(true)
                }    
            }
        }

        updateEClaimData({ [input]: value }, 'schemeDetails');
    }

    const getFileStatusText = file => {
        if (file) {
            return formatFileSize(file?.size);
        }
        return `Max 5MB`
    }

    const fileTypeIcons = {
        jpg: file_icon_jpg,
        jpeg: file_icon_jpeg,
        pdf: file_icon_pdf,
        tif: file_icon_tif,
        tiff: file_icon_tiff,
        png: file_icon_png
    };

    return (
        <>
            <div className="mvest-scheme-details-page">
                <div className="mvest-scheme-details-page-wrapper">

                    <OmApplicationReviewSection titleHeader="Additional Information">
                        <div slot="section-body">
                            <OmdsGrid>
                                <OmdsCell span="row" span-md="6">
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        label="Claim Type"
                                        value='PARTIAL WITHDRAWAL'
                                    />
                                </OmdsCell>
                                <OmdsCell span="row" span-md="7..">
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        label="Cash Value"
                                        value={`GH₵${eClaimData?.schemeDetails?.TotalCashValue}`}
                                    />
                                </OmdsCell>
                            </OmdsGrid>
                            <OmdsGrid>
                                <OmdsCell span="row" span-md="6">
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        label="Amount Available"
                                        value={`GH₵${(parseFloat(eClaimData?.schemeDetails?.CurrentCashValue) * 0.5).toFixed(2)}`}
                                    />
                                </OmdsCell>
                                <OmdsCell span="row" span-md="7..">
                                    <OmLabeledValueSummary
                                        isSensitive={true}
                                        label="Payment Method"
                                        value='Mobile Money'
                                    />
                                </OmdsCell>
                            </OmdsGrid>
                        </div>
                    </OmApplicationReviewSection>
                    

                    <OmFormDropdownFieldWrapper
                        id="purpose"
                        name="purpose"
                        useNativeMobileDropdown
                        required
                        size="full"
                        formId="schemeDetailsForm"
                        placeholder="Purpose"
                        align="left"
                        type="text"
                        value={eClaimData?.schemeDetails?.PartialWithdPurpose}
                        state={isInputValid(eClaimData?.schemeDetails?.PartialWithdPurpose)}
                        errorMessage={isInputValid(eClaimData?.schemeDetails?.PartialWithdPurpose) === 'error' ? 'Please select your purpose for this claim' : undefined }
                        options={
                            purposes &&
                            JSON.stringify(
                                purposes.map((option) => ({
                                    value: option.Description,
                                    label: option.Description,
                                }))
                            )
                        }
                        onOnSelect={(e) => updateEClaimData({ PartialWithdPurpose: e.detail.value }, 'schemeDetails') }
                    />
                    
                    <OmFormInputFieldWrapper
                        label="Requested Amount"
                        id="AmountAppliedFor"
                        name="AmountAppliedFor"
                        labelAlwaysShow={true}
                        isSensitive
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        keyboardMode='numeric'
                        placeholder="e.g. 200"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={eClaimData?.schemeDetails?.AmountAppliedFor}
                        state={isInputValid(eClaimData?.schemeDetails?.AmountAppliedFor)}
                        errorMessage={isInputValid(eClaimData?.schemeDetails?.AmountAppliedFor) === 'error' ? 'Requested amount cannot be more than GHc1,000' : undefined }
                        onPersist={(e) => handleChange(e)}
                    />

                    <OmFormDropdownFieldWrapper
                        id="ClaimDefaultTelcoCompany"
                        name="ClaimDefaultTelcoCompany"
                        useNativeMobileDropdown
                        required
                        size="full"
                        formId="schemeDetailsForm"
                        placeholder="Select MoMo"
                        align="left"
                        type="text"
                        value={eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany}
                        state={isInputValid(eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany)}
                        errorMessage={isInputValid(eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany) === 'error' ? 'Please select your MOMO network' : undefined }
                        options={
                            telcos &&
                            JSON.stringify(
                                telcos.map((option) => ({
                                    value: option.Name,
                                    label: option.Name,
                                }))
                            )
                        }
                        onOnSelect={(e) => updateEClaimData({ ClaimDefaultTelcoCompany: e.detail.value }, 'schemeDetails') }
                    />

                    <OmFormInputFieldWrapper
                        label="MOMO Number"
                        id="ClaimDefaultMobileWallet"
                        name="ClaimDefaultMobileWallet"
                        labelAlwaysShow={true}
                        isSensitive
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        keyboardMode='numeric'
                        maxlength={10}
                        placeholder="e.g. 0244XXXXXX"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={eClaimData?.schemeDetails?.ClaimDefaultMobileWallet}
                        state={isInputValid(eClaimData?.schemeDetails?.ClaimDefaultMobileWallet)}
                        errorMessage={isInputValid(eClaimData?.schemeDetails?.ClaimDefaultMobileWallet) === 'error' ? 'Please enter your MOMO number' : undefined }
                        onPersist={(e) => handleChange(e)}
                    />
                    {walletName ? <p style={{color:'red'}}>You are about to authorize claim payment to {walletName} momo wallet. There will be a mobile money charge of 1% on the withdrawal amount.</p> : ''}

                    <OmFormInputFieldWrapper
                        label='Ghana Card Number'
                        id="ghanaCardNumber"
                        name="ghanaCardNumber"
                        isSensitive
                        labelAlwaysShow={true}
                        maxLength={15}
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        placeholder='e.g. GHA-123456789-0'
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={eClaimData?.schemeDetails?.ghanaCardNumber}
                        state={isInputValid(eClaimData?.schemeDetails?.ghanaCardNumber)}
                        errorMessage={!isValidGhanaCardNumber(eClaimData?.schemeDetails?.ghanaCardNumber) ? 'Starts with GHA followed by 9 digits, and 1 digit at the end e.g:GHA-XXXXXXXXXX-X' : undefined}
                        onPersist={(e) => handleChange(e)}
                    />

                    <div style={{ marginTop: '2rem' }}>
                        <p><strong>Upload ID Card</strong></p>
                        <input type="file" accept={`image/png, image/jpg, image/jpeg`} onInput={(e)=> handleFileInput(e, 'memberIdCard')} className="omds-hidden" ref={memberIdCardInputFile} />  
                        <OmdsFileUploadFile
                            hasError={false}
                            onCancelUpload={() => removeFile('memberIdCard' )}
                            onRemove={() => removeFile('memberIdCard' )}
                            onRetryUploading={(e) => handleFileInput(e, 'memberIdCard')}
                            onFileClick={ ()=> memberIdCardInputFile?.current?.click()}
                            className="file-uploader-file">
                            {
                                memberIdCard !== null && <OmIcon className="file-upload-type-icon"  slot='leading-icon' height={10} width={10} imageUrl={fileTypeIcons[memberIdCardFilename?.split('.').slice(-1)]}/>
                            }
                            { memberIdCard !== null ? memberIdCardFilename : 'Upload ID Card'}
                            <div slot='status'>
                                {getFileStatusText(memberIdCard)}
                            </div>
                            {
                                memberIdCard === null && <OmdsIcon slot="suffix-icon" size={24} name="file_upload" className="file-upload-icon"></OmdsIcon>
                            }
                            { memberIdCard !== null &&
                                <div slot="hint-text"> 
                                    <OmFormError message={memberIdCard?.size > 5268045824 ? 'Max file size exceeded; Please, ID Card should not be more than 5MB' : undefined } />
                                </div>
                
                            }
                        </OmdsFileUploadFile>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <p><strong>Upload signature (jpg, jpeg and png only*)</strong></p>
                        <input type="file" accept={`image/png, image/jpg, image/jpeg`} onInput={(e)=> handleFileInput(e, 'memberSignature')} className="omds-hidden" ref={memberSignatureInputFile} />  
                        <OmdsFileUploadFile
                            hasError={false}
                            onCancelUpload={() => removeFile('memberSignature' )}
                            onRemove={() => removeFile('memberSignature' )}
                            onRetryUploading={(e) => handleFileInput(e, 'memberSignature')}
                            onFileClick={ ()=> memberSignatureInputFile?.current?.click()}
                            className="file-uploader-file"
                        >
                            {
                                memberSignature !== null &&  <OmIcon className="file-upload-type-icon" slot='leading-icon' height={10} width={10} imageUrl={fileTypeIcons[memberSignatureFilename?.split('.').slice(-1)]}/>
                            }
                            { memberSignature !== null ? memberSignatureFilename : 'Upload Signature'}
                            <div slot='status'>
                                {getFileStatusText(memberSignature)}
                            </div>
                            {
                                memberSignature === null && <OmdsIcon slot="suffix-icon" size={24} name="file_upload" className="file-upload-icon"></OmdsIcon>
                            }
                            { memberSignature !== null &&
                                <div slot="hint-text">
                                    <OmFormError message={ memberSignature?.size > 5268045824 ? 'Max file size exceeded; Please, Signature should not be more than 5MB' : undefined } />
                                </div>
                            }
                        </OmdsFileUploadFile> 
                    </div>
           
                    <OmModalLoader
                        slot="loading-modal"
                        loaderText="Please wait..."
                        verticalAlignMiddle={true}
                        open={loading}
                    />

                    <OmModalLoader
                        slot="loading-modal"
                        loaderText="Submitting claims information..."
                        verticalAlignMiddle={true}
                        open={eClaimData?.schemeDetails?.finalSubmit}
                    />
                </div>
            </div>

            <Disclaimer open={disclaimer} clientName={eClaimData?.schemeDetails?.client_name} onCloseHandler={(() => setDisclaimer(false))}/>
        </>
    );
};

export default ClaimPage;
