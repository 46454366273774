import React, { createContext, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const EClaimContext = createContext({
    // formData getters
    eClaimData: {
        schemeDetails: {
            policy_number: undefined,
            claim_type: 'PWD',
            client_name: undefined,
            PartialWithdPurpose: undefined,
            TotalCashValue: undefined,
            Status: undefined,
            CurrentCashValue: undefined,
            AmountAppliedFor: undefined,
            ClaimDefaultPay_method: 5,
            ClaimDefaultTelcoCompany: undefined,
            ClaimDefaultMobileWallet: undefined,
            mobile_number: undefined,
            plan_name: undefined,
            sum_assured: undefined,
            modal_prem: undefined,
            client_number: undefined,
            validatedOTP: false,
            finalSubmit: false,
            verifiedGhanaCard: false,
            ghanaCardNumber: 'GHA-',
            pdf_file: null
        }
    },

    memberSignatureFilename: '',
    memberIdCardFilename: '',
    memberSignature: null,
    memberIdCard: null,

    memberGhanaCard: null,
    memberGhanaCardFilename: '',

    beneficiaryListDataTravel: [],
    idToRemove: 0,
    idToEdit: 0,
    beneficiaryId: 0,
    hasAcceptedTermsAndCondition: false,
    // formData setters
    updateEClaimData: () => {},
    addBeneficiaryToListData: () => {},
    updatebeneficiaryListDataTravel: () => {},
    deleteBeneficiary: () => {},
    getBeneficiaryToEdit: () => {},
    setBeneficiaryId: () => {},
    setRemoveId: () => {},
    setEditId: () => {},
    updateObjectInArray: () => {},
    handleFileInput: () => {},
    removeFile:() => {},

    // app state
    claimActivePageIndex: 0,
    navigatePrevious: () => {},
    navigateContinue: () => {},
    navigateTo: () => {},

    // drawer
    isDrawerOpen: false,
    isEditDrawerOpen: false,
    isModalOpen: false,
    isEvenSplit: false,
    splitEvenly: () => {},
    toggleDrawer: () => {},
    toggleEditDrawer: () => {},
    toggleMaxBeneficiariesModal: () => {},
    acceptTermsAndCondition: () => {},
});

const EClaimProvider = ({ children }) => {

    const [claimActivePageIndex, setActivePageIndex] = useLocalStorage('claimActivePageIndex', 0);

    const [eClaimData, setEClaim] = useLocalStorage('eClaimData', {
        schemeDetails: {
            policy_number: undefined,
            claim_type: 'PWD',
            PartialWithdPurpose: undefined,
            TotalCashValue: undefined,
            CurrentCashValue: undefined,
            AmountAppliedFor: undefined,
            ClaimDefaultPay_method: 5,
            ClaimDefaultTelcoCompany: undefined,
            ClaimDefaultMobileWallet: undefined,
            mobile_number: undefined,
            plan_name: undefined,
            sum_assured: undefined,
            modal_prem: undefined,
            client_number: undefined,
            validatedOTP: false,
            finalSubmit: false,
            verifiedGhanaCard: false,
            ghanaCardNumber: 'GHA-',
            pdf_file: null
        }
    });
    const [beneficiaryListDataTravel, setbeneficiaryListDataTravel] = useLocalStorage('beneficiaryListDataTravel', []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEvenSplit, setIsEvenSplit] = useState(false);
    const [beneficiaryId, setId] = useState(0);
    const [idToRemove, setIdToRemove] = useState(0);
    const [idToEdit, setIdToEdit] = useState(0);
    const [memberSignatureFilename, setMemberSignatureFilename] = useLocalStorage('memberSignatureFilename','');
    const [memberIdCardFilename, setMemberIdCardFilename] = useLocalStorage('memberIdCardFilename','');
    const [memberIdCard, setMemberIdCard] = useLocalStorage('memberIdCard',null);
    const [memberSignature, setMemberSignature] = useLocalStorage('memberSignature',null);

    const [memberGhanaCard, setMemberGhanaCard] = useLocalStorage('memberGhanaCard',null);
    const [memberGhanaCardFilename, setMemberGhanaCardFilename] = useLocalStorage('memberGhanaCardFilename',null);
   
    
    const [hasAcceptedTermsAndCondition, setHasAcceptedTermsAndCondition] = useLocalStorage('hasAcceptedTermsAndCondition', false);
    const navigatePrevious = () => { setActivePageIndex((prevState) => prevState - 1); };
    const navigateContinue = () => { setActivePageIndex((prevState) => prevState + 1); };
    const navigateTo = (page) => { setActivePageIndex(page); };
    const toggleDrawer = () => { setIsDrawerOpen((prevState) => !prevState); };
    const splitEvenly = () => { setIsEvenSplit((prevState) => !prevState); };
    const toggleEditDrawer = () => { setIsEditDrawerOpen((prevState) => !prevState); };
    const toggleMaxBeneficiariesModal = () => { setIsModalOpen((prevState) => !prevState); };

    const acceptTermsAndCondition = () => { setHasAcceptedTermsAndCondition((prevState) => !prevState); };
    
    const updateEClaimData = (obj, page) => {
        setEClaim((prevData) => ({
            ...prevData,
            [page]: { ...prevData[page], ...obj },
        }));
    };

    const addBeneficiaryToListData = (obj) => { setbeneficiaryListDataTravel((prevArray) => [...prevArray, obj]); };

    const updatebeneficiaryListDataTravel = (arr) => { setbeneficiaryListDataTravel(arr); };

    const updateObjectInArray = (arr, { id, ...updatedObj }) => {
        const index = arr.findIndex((obj) => obj.id === id);
        if (index === -1) {
            setbeneficiaryListDataTravel(arr);
        }
        const newArr = [...arr];
        newArr[index] = {
            ...newArr[index],
            ...updatedObj,
        };
        setbeneficiaryListDataTravel(newArr);
    };

    const deleteBeneficiary = (id) => {
        setbeneficiaryListDataTravel((prevArray) =>
            prevArray.filter((obj) => obj.id !== id)
        );
    };

    const getBeneficiaryToEdit = () => {
        return beneficiaryListDataTravel[
            beneficiaryListDataTravel.findIndex((obj) => obj.id === idToEdit)
        ];
    };

    const setBeneficiaryId = () => { setId((prevId) => prevId + 1); };

    const setRemoveId = (idToRemove) => { setIdToRemove(idToRemove); };

    const setEditId = (idToEdit) => { setIdToEdit(idToEdit); };

    const handleFileInput = (event, inputType) => {
        const file = event?.target?.files[0];
        if (file) {
            const filename = file?.name;
            if (inputType === 'memberSignature') {
                setMemberSignatureFilename(filename);
                setMemberSignature(file);
            } else if (inputType === 'memberIdCard') {
                setMemberIdCardFilename(filename);
                setMemberIdCard(file);
            }
            // Encode the file data as a base64 string
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileData = reader.result;
                const fileSize = file.size;

                // Remove the data URL prefix to get the base64 string
                const base64String = fileData.replace(/^data:(.*,)?/, '');
                // Now, you can store the file content (string) in localStorage using your custom hook.
                if (inputType === 'memberGhanaCard') {
                    setMemberGhanaCardFilename(filename);
                    setMemberGhanaCard({data:fileData, size: fileSize, base64String});
                }
            };
        }
    };

    const removeFile = (inputType) => {
        if (inputType === 'memberSignature') {
            setMemberSignatureFilename('');
            setMemberSignature(null);
        } else if (inputType === 'memberIdCard') {
            setMemberIdCardFilename('');
            setMemberIdCard(null);
        }else if (inputType === 'memberGhanaCard') {
            setMemberGhanaCardFilename('');
            setMemberGhanaCard(null);
        }
    };

    return (
        <EClaimContext.Provider
            value={{
                eClaimData,
                claimActivePageIndex,
                isDrawerOpen,
                beneficiaryListDataTravel,
                beneficiaryId,
                isModalOpen,
                isEditDrawerOpen,
                idToRemove,
                idToEdit,
                hasAcceptedTermsAndCondition,
                memberSignatureFilename,
                memberIdCardFilename,
                memberSignature,
                memberIdCard,
                memberGhanaCard,
                memberGhanaCardFilename,
                updateEClaimData,
                navigateContinue,
                navigatePrevious,
                navigateTo,
                toggleDrawer,
                addBeneficiaryToListData,
                updatebeneficiaryListDataTravel,
                deleteBeneficiary,
                setRemoveId,
                setEditId,
                toggleMaxBeneficiariesModal,
                setBeneficiaryId,
                toggleEditDrawer,
                getBeneficiaryToEdit,
                updateObjectInArray,
                splitEvenly,
                acceptTermsAndCondition,
                handleFileInput,
                removeFile
            }}
        >
            {children}
        </EClaimContext.Provider>
    );
};

export { EClaimContext, EClaimProvider };
