import React from 'react';
import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../../ghana/applications/eclaim/ApplicationWizardHeader';
import ApplicationWizardFooter from '../../ghana/applications/eclaim/ApplicationWizardFooter';
import { EClaimProvider } from '../../ghana/applications/eclaim/context/EClaimContext';
import Outlet from '../../ghana/applications/eclaim/pages/Outlet';

const EClaimPage = () => {
    return (
        <EClaimProvider>
            <ApplicationLayout>
                <OmApplicationPage>
                    <div slot="header">
                        <ApplicationWizardHeader callMeBack={{}} />
                    </div>
                    <div slot="content-no-sidebar">
                        <Outlet />
                    </div>
                    <div slot="footer">
                        <ApplicationWizardFooter />
                    </div>
                </OmApplicationPage>
            </ApplicationLayout>
        </EClaimProvider>
    );
};

export default EClaimPage;
