import { objectToFormData, getEmergentPayCredentials, getSlamsBaseURL } from '../../../../ghana/utils/eclaim-utils/util';

const generateBearerToken = async (clientId, clientSecret) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/auth/tokenRequest?client_id=${clientId}&client_secret=${clientSecret}`,
            {
                method: 'POST',
                headers: {}
            }
        );

        const data = await response.json();
        return data?.access_token;
    } catch (error) {
        console.error('Error generating bearer token:', error);
        throw error;
    }
};

export const saveEClaimOnSlams = async (clientData) => {
    try {
        const clientId = getEmergentPayCredentials().client_id;
        const clientSecret = getEmergentPayCredentials().client_secret;

        const token = await generateBearerToken(clientId, clientSecret);

        const clientFormData = new FormData();
        clientFormData.append('policy_number', clientData.policy_number)
        clientFormData.append('claim_type', clientData.claim_type)
        clientFormData.append('PartialWithdPurpose', clientData.PartialWithdPurpose)
        clientFormData.append('TotalCashValue', clientData.TotalCashValue)
        clientFormData.append('CurrentCashValue', clientData.CurrentCashValue)
        clientFormData.append('AmountAppliedFor', clientData.AmountAppliedFor)
        clientFormData.append('ClaimDefaultPay_method', clientData.ClaimDefaultPay_method)
        clientFormData.append('ClaimDefaultTelcoCompany', clientData.ClaimDefaultTelcoCompany)
        clientFormData.append('ClaimDefaultMobileWallet', clientData.ClaimDefaultMobileWallet)
        clientFormData.append('IdNumber', clientData.IdNumber)
        clientFormData.append('id_file', clientData.id_file)
        clientFormData.append('signature_file', clientData.signature_file)
        clientFormData.append('client_identifier', clientData.client_identifier)
        // const clientFormData = objectToFormData(clientData)
        // console.log('...registring client', clientFormData);
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/claim/saveEClaims`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: clientFormData,
            }
        );

        let data = await response.json();
        localStorage.setItem('OM_CLAIM_NO', data?.claim_no || data?.record_id)
        data.token = token;
        return data;

    } catch (error) {
        console.error('Error:', error);
    }
};

export const getTelcosFromSlams = async (token) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/getTelcos`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data?.Telcos;

    } catch (error) {
        console.error('Error:', error);
    }
};

export const getCashValueFromSlams = async (token, policy_no) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/getCashValue?policy_no=${policy_no}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data?.CashValue;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getPurposesFromSlams = async (token) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/fetchReasons`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data?.Reasons.filter(item => item.Description !== 'OTHER ');
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getClaimTypesFromSlams = async (token) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/fetchReasons`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data?.Reasons;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getPolicyDetailsFromSlams = async (token, policy_no) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/validatePolicy?policy_no=${policy_no}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const validatePolicyOTPFromSlams = async (token, policy_no, otp) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/validateOTP?policy_no=${policy_no}&otp=${otp}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data?.success;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const generatePDFromSlams = async (token, id) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/claim/generatePDF?id=${id}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = response.json();
        return data;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const verifyGhanaCard = async (idNumber) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/claim/HubtelVerifyGhanaCard?IdNumber=${idNumber}`,
            {
                method: 'GET',
                headers: {
                    // Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = response.json();
        return data;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const verifyMoMoWllet = async (telcoCompany, momo_number) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/claim/HubtelVerifyMOMO?ClaimDefaultTelcoCompany=${telcoCompany}&ClaimDefaultMobileWallet=${momo_number}`,
            {
                method: 'GET',
                headers: {
                    // Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = response.json();
        return data;
        
    } catch (error) {
        console.error('Error:', error);
    }
};

export const generateToken = async () => {
    try {
        const clientId = getEmergentPayCredentials().client_id;
        const clientSecret = getEmergentPayCredentials().client_secret;

        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/auth/tokenRequest?client_id=${clientId}&client_secret=${clientSecret}`,
            {
                method: 'POST',
                headers: {}
            }
        );

        const data = await response.json();
        return data?.access_token;
    } catch (error) {
        console.error('Error generating bearer token:', error);
        throw error;
    }
};

export const verifyGhanaCardByNIA = async (idNumber, photoUri) => {
    try {
        const postData = {
            cardNumber: idNumber,
            imageBase64String: photoUri
        };
        const response = await fetch(
            `https://test-payment.oldmutual.com.gh/api/v1/verify-card`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': 'hgjku78uo8-cte736565r7656x4538uip80v-0-ty47536',
                },
                body: JSON.stringify(postData),
            }
        );
        let data = await response.json();
        return data;
    } catch (error) {
        console.error('Ghana Card Verification Error:', error);
    }
};