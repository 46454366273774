import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import {
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
    OmIcon,
    Om1ColLayout,
} from '@om/component-library-react';
import OmButton from '../../../../../components/forms/OmButton';
import { EClaimContext } from './context/EClaimContext';
import { PAGE_INDICES, PROGRESS_BAR_STEPS } from './constants/routing';

const ApplicationWizardHeader = ({ callMeBack }) => {
    const { claimActivePageIndex } = useContext(EClaimContext);
    const closeHander = () => {
        let check  = confirm('Are you sure you want to close this page?')
        if(check){
            if (typeof window !== 'undefined') {
                localStorage.clear();
            }
            navigate('/');
        }
    };

    return (
        <OmApplicationWizardHeader
            helpNumber="0307 000 600"
            className="banner-image"
        >
            <div slot="wizard-title">
                <p>
                    {Object.keys(PAGE_INDICES)
                        [claimActivePageIndex]?.toString()
                        .replace('_', ' ')
                        .toUpperCase()}
                </p>
                <h5>
                    <strong>Instant Claim</strong>
                </h5>
            </div>

            <span slot="contact-button">
                <OmButton
                    type="secondary"
                    actionType="Call Me Back"
                    size="small"
                    text="Call me back"
                    callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ''}
                />
            </span>
            <a slot="close-button" className='joureyCloseNameContainer'>
                <span className='joureyCloseName' onClick={() => closeHander()}>Close</span>
                <OmIcon
                    className="close-icon"
                    iconName="close"
                    onClick={() => closeHander()}
                /> 
            </a>

            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="Instant Claim"
                        closable
                        hideDesktop={claimActivePageIndex === PAGE_INDICES.SUCCESS}
                        // hideMobile={claimActivePageIndex === PAGE_INDICES.SUCCESS}
                        className="banner-image"
                        steps={PROGRESS_BAR_STEPS}
                        index={claimActivePageIndex}>
                        <OmButton
                            actionType="url"
                            slot="close-application"
                            type="text"
                            size="small"
                            // url="/"
                            text="CLOSE"
                            onClick={() => closeHander()}
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;
