import React, { useContext } from 'react';
import './ReviewPage.scss';
import { EClaimContext } from '../../context/EClaimContext';
import { OmApplicationReviewSection,OmLabeledValueSummary,OmApplicationSummaryDetailsCard } from '@om/component-library-react';
import { OmdsCell,OmdsGrid } from '@mom-omds/react-design-system-components/dist/generated/components';

const DetailSummaryPage = () => {
    
    const { eClaimData } = useContext(EClaimContext);

    return (
        <div className="mvest-scheme-details-page">
            <div className="mvest-scheme-details-page-wrapper">
                <OmdsGrid isFixed>
                    <OmdsCell span="row" span-md="9">
                        <div>
                            <h4 className="page-heading sessioncamhidetext">Please confirm your details</h4>
                            <div>
                                <OmApplicationReviewSection titleHeader="Personal Details">
                                    <div slot="section-body">
                                        <OmdsGrid>
                                            <OmdsCell span="row" span-md="6">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="POLICY PLAN"
                                                    value={eClaimData?.schemeDetails?.plan_name}
                                                />
                                            </OmdsCell>
                                            <OmdsCell span="row" span-md="7..">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="POLICY NUMBER"
                                                    value={eClaimData?.schemeDetails?.policy_number}
                                                />
                                            </OmdsCell>
                                        </OmdsGrid>
                                        <OmdsGrid>
                                            <OmdsCell span="row" span-md="6">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="FULL NAME"
                                                    value={eClaimData?.schemeDetails?.client_name}
                                                />
                                            </OmdsCell>
                                            <OmdsCell span="row" span-md="7..">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="CONTACT NUMBER"
                                                    value={eClaimData?.schemeDetails?.mobile_number}
                                                />
                                            </OmdsCell>
                                        </OmdsGrid>
                                        <OmdsGrid>
                                            <OmdsCell span="row" span-md="6">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="SUM ASSURED"
                                                    value={`GH₵${eClaimData?.schemeDetails?.sum_assured}`}
                                                />
                                            </OmdsCell>
                                            <OmdsCell span="row" span-md="7..">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="MODAL PREMIUM"
                                                    value={`GH₵${eClaimData?.schemeDetails?.modal_prem}`}
                                                />
                                            </OmdsCell>
                                        </OmdsGrid>
                                        <OmdsGrid>
                                            <OmdsCell span="row" span-md="6">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="STATUS"
                                                    value={`${eClaimData?.schemeDetails?.Status}`}
                                                />
                                            </OmdsCell>
                                            <OmdsCell span="row" span-md="7..">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label="CASH VALUE"
                                                    value={`GH₵${eClaimData?.schemeDetails?.TotalCashValue}`}
                                                />
                                            </OmdsCell>
                                        </OmdsGrid>
                                    </div>
                                </OmApplicationReviewSection>
                            </div>
                        </div>
                    </OmdsCell>
                </OmdsGrid>
            </div>
        </div>
    );
};

export default DetailSummaryPage;
