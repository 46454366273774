import React, { useContext, useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/eclaim-configs/config';
import {OmFormInputFieldWrapper,OmModalLoader} from '@om/component-library-react';
import { EClaimContext } from '../../../eclaim/context/EClaimContext';
import {isInputValid } from '../../../../../ghana/utils/eclaim-utils/util';
import OmButton from '../../../../../../../components/forms/OmButton';
import ValidateOTP from './ValidateOTP';
import { generateToken, getPolicyDetailsFromSlams } from '../../server/api';


const ValidatePolicyPage = () => {

    const { eClaimData, updateEClaimData } = useContext(EClaimContext);
    const [otpModal, setOTPModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(null)


    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;

        updateEClaimData({ [input]: value }, 'schemeDetails');
    }

    const toggleModal = () => {setOTPModal(!otpModal)}
    const toggleLoading = () => {setLoading(!loading)}

    const submitValidate = async () => {
        if(eClaimData?.schemeDetails?.policy_number){
            toggleLoading()

            let _token = null;
            if(token){
                _token = token;
            }else{
                _token = await generateToken();
                setToken(_token)
            }
            
            const policy_details = await getPolicyDetailsFromSlams(_token, eClaimData?.schemeDetails?.policy_number);

            toggleLoading()
            //|| policy_details?.length < 1
            if(!policy_details.success){
                setLoading(false)
                alert(`Error: ${policy_details.message}`)
            }else{
                setLoading(false)
                if(policy_details?.PolicyDetails[0].mobile.length < 10){
                    alert('Your records are not up to date. Please visit our nearest branch to update your records!')
                    return;
                }
                updateEClaimData({ 
                    client_name: policy_details?.PolicyDetails[0].client_name, 
                    mobile_number: policy_details?.PolicyDetails[0].mobile, 
                    plan_name: policy_details?.PolicyDetails[0].plan_name, 
                    sum_assured: policy_details?.PolicyDetails[0].sa, 
                    modal_prem: policy_details?.PolicyDetails[0].modal_prem, 
                    client_number: policy_details?.PolicyDetails[0].client_number, 
                    TotalCashValue: policy_details?.PolicyDetails[0].CashValue, 
                    CurrentCashValue: policy_details?.PolicyDetails[0].CashValue, 
                    Status: policy_details?.PolicyDetails[0].Status, 
                }, 'schemeDetails');
                localStorage.setItem('CASH_VALUE', policy_details?.PolicyDetails[0].CashValue)
                toggleModal()
            }  
        }
    }

    return (
        <>
            <div className="mvest-scheme-details-page">
                <div className="mvest-scheme-details-page-wrapper">
                    <h4 className="page-heading sessioncamhidetext">Please validate your policy number</h4>
                    
                    <OmFormInputFieldWrapper
                        label="Policy Number"
                        id="policy_number"
                        name="policy_number"
                        labelAlwaysShow={true}
                        isSensitive
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        placeholder="e.g. P304930D"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={eClaimData?.schemeDetails?.policy_number}
                        state={isInputValid(eClaimData?.schemeDetails?.policy_number)}
                        errorMessage={isInputValid(eClaimData?.schemeDetails?.policy_number) === 'error' ? 'Please enter your Policy Number' : undefined }
                        onPersist={(e) => handleChange(e)}
                    />
           
                    <div style={{ marginTop: '15px'}}>
                        <OmButton
                            iconName="search"
                            slot="right"
                            onClick={() => submitValidate()}
                            type="primary"
                            size="small"
                            text='Validate Policy Number' />
                    </div>
            
                    <ValidateOTP mobile={eClaimData?.schemeDetails?.mobile_number} open={otpModal} onCloseHandler={toggleModal} token={token}/>

                    <OmModalLoader
                        slot="loading-modal"
                        loaderText="Please wait while we validate your policy..."
                        verticalAlignMiddle={true}
                        open={loading}
                    />
                </div>
            </div>
        </>
    );
};

export default ValidatePolicyPage;
