import React, { useContext, useEffect, useState } from 'react';
import './successPage.scss';
import {
    OmApplicationThankYou,
    OmApplicationThankYouPage,
} from '@om/component-library-react';
import checkIcon from '../../assets/images/check.svg';
import { EClaimContext } from '../../context/EClaimContext';
import { removeLocalStorage } from '../../../../utils/eclaim-utils/util';
import Feedback from './Feedback';

const SuccessPage = () => {
    const { eClaimData } = useContext(EClaimContext);
    const [name, setName] = useState('')
    const [claimNo, setClaimNo] = useState('')
    const [rateModaal, setRateModal] = useState(true)

    useEffect(() => {
        setName(eClaimData?.schemeDetails?.client_name)
        setClaimNo(localStorage.getItem('OM_CLAIM_NO'))
        removeLocalStorage();
    },[])

    return (
        <div className="mvest-thank-you-page">
            <OmApplicationThankYouPage>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ paddingTop: '2rem'}}>
                        <OmApplicationThankYou
                            // slot="thank-you"
                            imageLink={checkIcon}
                            personName={`${name}`}
                            thankYouCaption={
                                'Your claim request is received, and your payment is being processed. \n'
                            }
                            referenceNumber={`${claimNo}`}
                            contactCaption={
                                'Kindly contact us on \n' + '<a href="tel:0307000600"><strong>0307 000 600</strong></a>' +
                ' or via WhatsApp: <a href="https://wa.me/0508333537" target="_blank" rel="noreferrer">0508333537</a> if payment doesn\'t reflect in the next 30 minutes. \n' +
                'Thank you for choosing Old Mutual.'
                            }
                            closeButtonLink='/make-eclaim'
                            // buttonName={""}
                            // buttonLink={""}
                        />
                    </div>
                </div>
            </OmApplicationThankYouPage>
            <Feedback open={rateModaal} onCloseHandler={() => setRateModal(false)} />
        </div>
    );
};

export default SuccessPage;
