import React, { useContext } from 'react';
import OmButton from '../../../../../components/forms/OmButton';
import { OmApplicationWizardFooter } from '@om/component-library-react';
import { EClaimContext } from './context/EClaimContext';
import { PAGE_INDICES } from './constants/routing';
import { areAllInputsInvalid, stripZeroPhoneNumber } from '../../../ghana/utils/eclaim-utils/util';
import { generatePDFromSlams, saveEClaimOnSlams, verifyGhanaCard, verifyMoMoWllet } from './server/api';
import { getPurposeReference, getTelcoReference } from '../../utils/eclaim-utils/util';


const ApplicationWizardFooter = () => {

    const {
        claimActivePageIndex,
        navigateContinue,
        navigatePrevious,
        eClaimData,
        updateEClaimData,
        memberSignature,
        memberIdCard
    } = useContext(EClaimContext);

    const toggleLoading = (val) => {
        updateEClaimData({ finalSubmit: val }, 'schemeDetails')
        sessionStorage.setItem('toggleProcess', val)
    }

    let continueText = claimActivePageIndex === PAGE_INDICES.CLAIM_FORM ? 'SUBMIT CLAIM' : 'CONTINUE';
   
    const isLastPage = claimActivePageIndex === PAGE_INDICES.SUCCESS;

    const validateFormNextStep = () => {
        if (claimActivePageIndex === PAGE_INDICES.VALIDATE_GHANA_CARD) {
            return eClaimData?.schemeDetails?.verifiedGhanaCard;
        }if (claimActivePageIndex === PAGE_INDICES.VALIDATE_POLICY) {
            return eClaimData?.schemeDetails?.validatedOTP;
        }else if (claimActivePageIndex === PAGE_INDICES.SUMMARY_DETAILS) {
            return true;
        }else if (claimActivePageIndex === PAGE_INDICES.CLAIM_FORM) {
            let checkValues = {
                policy_number: eClaimData?.schemeDetails?.policy_number,
                PartialWithdPurpose: getPurposeReference(eClaimData?.schemeDetails?.PartialWithdPurpose),
                TotalCashValue: eClaimData?.schemeDetails?.TotalCashValue,
                CurrentCashValue: eClaimData?.schemeDetails?.CurrentCashValue,
                AmountAppliedFor: eClaimData?.schemeDetails?.AmountAppliedFor,
                ghanaCardNumber: eClaimData?.schemeDetails?.ghanaCardNumber,
                ClaimDefaultTelcoCompany: getTelcoReference(eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany),
            }
            return areAllInputsInvalid(checkValues);
        }
        return;
    };


    const submit = async () => {
        if(parseFloat(eClaimData?.schemeDetails?.CurrentCashValue) > parseFloat(localStorage.getItem('CASH_VALUE'))){
            alert(`Your cash value cannot be more than GHC${localStorage.getItem('CASH_VALUE')}`)
            return;
        }else if(parseFloat(eClaimData?.schemeDetails?.AmountAppliedFor) > 1000){
            alert(`Your requested amount cannot be more than GHC1,000`)
            return;
        }else if(memberIdCard == null && memberSignature == null){
            alert('Upload both signature and ID Card which should be less than 1MB!')
            return;
        }else if(memberSignature?.size > 5268045824){
            alert('Max file size exceeded; Signature file size should not be more than 5MB')
            return;
        }else if(memberIdCard?.size > 5268045824){
            alert('Max file size exceeded; ID Card file size should not be more than 5MB')
            return;
        }


        // toggleLoading(true)
        // const verifyGHCard = await verifyGhanaCard(eClaimData?.schemeDetails?.ghanaCardNumber)
        // if(verifyGHCard.success == false){
        //     toggleLoading(false)
        //     alert('Ghana card provided is invalid!')
        //     return;
        // }

        // const verifyMoMo = await verifyMoMoWllet(getTelcoReference(eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany), '233'+stripZeroPhoneNumber(eClaimData?.schemeDetails?.ClaimDefaultMobileWallet))
        // if(verifyMoMo.success == false){
        //     toggleLoading(false)
        //     alert('The momo wallet provided is not registered!')
        //     return;
        // }

        try {
            toggleLoading(true)
            const claimPayload = {
                policy_number: eClaimData?.schemeDetails?.policy_number,
                claim_type: 'PWD',
                PartialWithdPurpose: getPurposeReference(eClaimData?.schemeDetails?.PartialWithdPurpose),
                TotalCashValue: eClaimData?.schemeDetails?.TotalCashValue,
                CurrentCashValue: eClaimData?.schemeDetails?.CurrentCashValue,
                AmountAppliedFor: eClaimData?.schemeDetails?.AmountAppliedFor,
                ClaimDefaultPay_method: 5,
                ClaimDefaultTelcoCompany: getTelcoReference(eClaimData?.schemeDetails?.ClaimDefaultTelcoCompany),
                ClaimDefaultMobileWallet: eClaimData?.schemeDetails?.ClaimDefaultMobileWallet,
                IdNumber: eClaimData?.schemeDetails?.ghanaCardNumber,
                id_file: memberIdCard,
                signature_file: memberSignature,
                client_identifier: 1
            }
            // console.log('Registering payload::',claimPayload)
            const res = await saveEClaimOnSlams(claimPayload)

            if (res?.record_id){
                const pdf = await generatePDFromSlams(res?.token, res?.record_id)
                // console.log('PDF DATA::',pdf);
                if(pdf){
                    let cleanedUrl = pdf.pdf_path.replace(/\\\//g, '/');
                    updateEClaimData({ pdf_file: cleanedUrl }, 'schemeDetails')
                    window.open(cleanedUrl, '_blank');
                }
                toggleLoading(false)
                navigateContinue()
            }else{
                toggleLoading(false)
                alert(`Error: ${res?.message}`)
            }
        } catch (error) {
            toggleLoading(false)
            alert(error)
            console.error('Submission failed:', error);
        }

    }

    const nextActionButton = () => {
        if(claimActivePageIndex === PAGE_INDICES.CLAIM_FORM){
            submit()
        }else{
            navigateContinue()
        }
    }

    return (
        !isLastPage && (
            <OmApplicationWizardFooter
                index={claimActivePageIndex}
                totalCount="totalSteps"
            >
                <OmButton
                    slot="left"
                    onClick={() => navigatePrevious()}
                    type="text"
                    size="small"
                    text="Previous"
                    disabled={claimActivePageIndex <= PAGE_INDICES.VALIDATE_POLICY}
                />

                <OmButton
                    disabled={!validateFormNextStep()}
                    iconName="arrow_forward"
                    slot="right"
                    onClick={() => nextActionButton()}
                    type="primary"
                    size="small"
                    text={continueText}
                />
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationWizardFooter;
