export const PAGE_INDICES = {
    VALIDATE_GHANA_CARD: 0,
    VALIDATE_POLICY: 1,
    SUMMARY_DETAILS: 2,
    CLAIM_FORM: 3,
    SUCCESS: 4,
};

export const PROGRESS_BAR_STEPS = [
    'Verify Ghana Card',
    'Validate Policy',
    'Policy Details',
    'Claim Form',
].join('|');
