import React from 'react';
import { OmModal } from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';

const Disclaimer = ({open, onCloseHandler, clientName}) => {

    return (
        <OmModal
            open={open}
            isCentered="true"
            overlayName="cancel-eclaim-journey">
            <div slot="modal-content" className="cancel-modal">
                <p className="cancel-modal-title">Dear {clientName}, kindly ensure that the MOMO number is correct as Old Mutual Life Assurance Company (Ghana) Ltd will not be liable for any payment to a wrong number.</p>
                
                <div className="cancel-modal-buttons">
                    <OmButton
                        type="primary"
                        text='Close'
                        size="small"
                        onClick={onCloseHandler}
                    />
                </div>
            </div>
        </OmModal>
    );
};

export default Disclaimer;
