import React, { useContext, useState, useRef, useEffect } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../configs/eclaim-configs/config';
import {OmFormInputFieldWrapper, OmFormError, OmIcon, OmModalLoader, OmModal} from '@om/component-library-react';
import { EClaimContext } from '../../context/EClaimContext';
import {isInputValid, formatFileSize, isValidGhanaCardNumber } from '../../../../utils/eclaim-utils/util';
import { OmdsFileUploadFile, OmdsIcon } from '@mom-omds/react-design-system-components/dist/generated/components';
import OmButton from '../../../../../../../components/forms/OmButton';
import file_icon_jpeg from '../../assets/icons/File_Icon_JPEG.svg'
import file_icon_jpg from '../../assets/icons/File_Icon_JPG.svg'
import file_icon_pdf from '../../assets/icons/File_Icon_PDF.svg'
import file_icon_png from '../../assets/icons/File_Icon_PNG.svg'
import file_icon_tif from '../../assets/icons/File_Icon_TIF.svg'
import file_icon_tiff from '../../assets/icons/File_Icon_TIFF.svg'
import { verifyGhanaCardByNIA } from '../../server/api';


const GhanaCardPage = () => {

    const { eClaimData, updateEClaimData, memberGhanaCardFilename, memberGhanaCard, handleFileInput, removeFile, navigateContinue } = useContext(EClaimContext);

    const [loading, setLoading] = useState(false)
    const [selfieModal, setSelfieModal] = useState(false)
    const [isSelfie, setIsSelfie] = useState(false)
    
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const [hasPhoto, setHasPhoto] = useState(false);
    const [photoUri, setPhotoUri] = useState('');
    const [photoUriBase64, setPhotoUriBase64] = useState(null);
    const [stream, setStream] = useState(null);

    const memberIdCardInputFile = useRef(null)

    const toggleLoading = () => setLoading(!loading)

    const toggleModal = () => {
        if(!selfieModal){
            getVideo()
        }
        setSelfieModal(!selfieModal)
    }

    const handleChange = async (e) => {
        updateEClaimData({ ghanaCardNumber: e.detail }, 'schemeDetails');
    }

    const getFileStatusText = file => {
        if (file) {
            return formatFileSize(file?.size);
        }
        return `Max 5MB`
    }

    const fileTypeIcons = {
        jpg: file_icon_jpg,
        jpeg: file_icon_jpeg,
        pdf: file_icon_pdf,
        tif: file_icon_tif,
        tiff: file_icon_tiff,
        png: file_icon_png
    };

    const submitValidate = async () => {
        if(isSelfie){
            if(photoUri == null){
                alert('Error: Please take a selfie to continue!')
                return;
            }
        }else{
            if(memberGhanaCard == null){
                alert('Error: Please select a picture or take a selfie to verify!')
                return;
            }
        }
        
        if(eClaimData?.schemeDetails?.ghanaCardNumber.length < 10){
            alert('Error: Please enter your Ghana Card Number to continue!')
            return;
        }
        if(memberGhanaCard?.size > 5268045824){
            alert('Error: Photo size cannot exceed 5MB!')
            return;
        }

        toggleLoading()
        const response = await verifyGhanaCardByNIA(eClaimData?.schemeDetails?.ghanaCardNumber, isSelfie ? photoUriBase64 : memberGhanaCard.base64String);
        if(response?.status){
            toggleLoading()
            updateEClaimData({ verifiedGhanaCard: true }, 'schemeDetails');
            alert('Success: '+response?.message)
            // navigateContinue()
        }else{
            setLoading(false)
            alert('Error: '+response?.message)
        }
        setLoading(false)
    }

    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
                let video = videoRef.current;
                video.srcObject = stream;
                // console.log('SREAM::',stream);
                video.play();
                setStream(stream);
            })
            .catch((err) => {
                console.error('error:', err);
            });
    };

    const takePhoto = () => {
        const width = 150;
        const height = width / (16 / 9);

        let video = videoRef.current;
        let photo = photoRef.current;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);

        const dataUrl = photo.toDataURL('image/png');
        setPhotoUri(dataUrl);

        const base64String = dataUrl.replace(/^data:image\/png;base64,/, '');
        setPhotoUriBase64(base64String)
        console.log('photoURL::',dataUrl)

        // localStorage.setItem('memberGhanaCard', dataUrl)
        // localStorage.setItem('memberGhanaCardFilename', 'selfie.png')

        setHasPhoto(true);
        setIsSelfie(true)
    };

    const closePhoto = () => {
        let photo = photoRef.current;
        let ctx = photo.getContext('2d');
        ctx.clearRect(0, 0, photo.width, photo.height);
        setHasPhoto(false);
        stopVideo();
        toggleModal()
    };

    // Stop the video stream
    const stopVideo = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        }
    }

    return (
        <>
            <div className="mvest-scheme-details-page">
                <div className="mvest-scheme-details-page-wrapper">

                    <p>1. Take a high-resolution picture of yourself with your phone.</p>
                    <p>2. Crop the picture to shoulder level/passport picture size.</p>
                    <p>3. Save the picture on your phone.</p>
                    <p>4. Upload the picture and click on verify ID.</p>
                    <p>5. Once the verification is successful, click on proceed.</p>

                    <OmFormInputFieldWrapper
                        label='Ghana Card Number'
                        id="ghanaCardNumber"
                        name="ghanaCardNumber"
                        isSensitive
                        labelAlwaysShow={true}
                        maxLength={15}
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        placeholder='e.g. GHA-123456789-0'
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={eClaimData?.schemeDetails?.ghanaCardNumber}
                        state={isInputValid(eClaimData?.schemeDetails?.ghanaCardNumber)}
                        errorMessage={!isValidGhanaCardNumber(eClaimData?.schemeDetails?.ghanaCardNumber) ? 'Starts with GHA followed by 9 digits, and 1 digit at the end e.g:GHA-XXXXXXXXXX-X' : undefined}
                        onPersist={(e) => handleChange(e)}
                    />

                    {isSelfie ? 
                        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            <strong>Selfie Photo</strong> <br/>
                            <img src={photoUri} alt='selfie'/>
                        </div>
                        : ''
                    }
                   
                    
                    <div style={{ marginTop: '2rem', marginBottom: '15px' }}>
                        <p><strong>Upload Passport Photo</strong> <a target='_blak' style={{ color: '#5EB251' }} href='https://eu-images.contentstack.com/v3/assets/blta881ace037f02dd2/blt29cb8e9bb99e1847/66a75fa3db1d21796cb57f17/passport-sample.jpeg'>(See sample)</a></p>
                        {/* <p><strong>Upload Passport Photo</strong> <a target='_blak' style={{ color: '#5EB251' }} href='https://eu-images.contentstack.com/v3/assets/blta881ace037f02dd2/blt29cb8e9bb99e1847/66a75fa3db1d21796cb57f17/passport-sample.jpeg'>(See sample)</a> or <span style={{ textDecoration:'underline', cursor:'pointer' }} onClick={toggleModal}>Take a selfie</span></p> */}
                        <input type="file" accept={`image/png, image/jpg, image/jpeg`} onInput={(e)=> handleFileInput(e, 'memberGhanaCard')} className="omds-hidden" ref={memberIdCardInputFile} />  
                        <OmdsFileUploadFile
                            hasError={false}
                            onCancelUpload={() => removeFile('memberGhanaCard' )}
                            onRemove={() => removeFile('memberGhanaCard' )}
                            onRetryUploading={(e) => handleFileInput(e, 'memberGhanaCard')}
                            onFileClick={ ()=> memberIdCardInputFile?.current?.click()}
                            className="file-uploader-file">
                            {
                                memberGhanaCard !== null && <OmIcon className="file-upload-type-icon"  slot='leading-icon' height={10} width={10} imageUrl={fileTypeIcons[memberGhanaCardFilename?.split('.').slice(-1)]}/>
                            }
                            { memberGhanaCard !== null ? memberGhanaCardFilename : 'Upload Facial Selfie or Photo'}
                            <div slot='status'>
                                {getFileStatusText(memberGhanaCard)}
                            </div>
                            {
                                memberGhanaCard === null && <OmdsIcon slot="suffix-icon" size={24} name="file_upload" className="file-upload-icon"></OmdsIcon>
                            }
                            { memberGhanaCard !== null &&
                                <div slot="hint-text"> 
                                    <OmFormError message={memberGhanaCard?.size > 5268045824 ? 'Max file size exceeded; Please, Facial photo should not be more than 5MB' : undefined } />
                                </div>
                
                            }
                        </OmdsFileUploadFile>
                    </div>

                    <OmButton
                        slot="right"
                        onClick={() => submitValidate()}
                        type="primary"
                        size="small"
                        text='Verify Ghana Card' />
           
                    <OmModalLoader
                        slot="loading-modal"
                        loaderText="Verifying your Ghana Card..."
                        verticalAlignMiddle={true}
                        open={loading}
                    />

                    <OmModal
                        open={selfieModal}
                        isCentered="true"
                        overlayName="cancel-eclaim-journey">
                        <div slot="modal-content" className="cancel-modal">

                            <div className="camera" style={{ textAlign: 'center' }}>
                                <div className="camera-container">
                                    <video ref={videoRef}></video>
                                    <div style={{ textAlign: 'center', align: 'center' }}>
                                        <OmButton
                                            slot="right"
                                            onClick={() => takePhoto()}
                                            type="primary"
                                            size="small"
                                            text='Take Photo' />
                                    </div>
                                </div>
                                <div className={'result ' + (hasPhoto ? 'hasPhoto' : '')}>
                                    <canvas ref={photoRef}></canvas>
                                    <div style={{ textAlign: 'center', align: 'center' }}>
                                        <OmButton
                                            slot="right"
                                            onClick={() => closePhoto()}
                                            type="primary"
                                            size="small"
                                            text='Close' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OmModal>
                </div>
            </div>
        </>
    );
};

export default GhanaCardPage;
